import { render, staticRenderFns } from "./ContingentLiabilities.vue?vue&type=template&id=246cc164&"
import script from "./ContingentLiabilities.vue?vue&type=script&lang=js&"
export * from "./ContingentLiabilities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports